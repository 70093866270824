import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import {environment} from './utils';

if (environment !== 'development') {
   Sentry.init({
      dsn: 'https://b8c84c013c1e4e27823512757af6c6af@sentry.io/1803481',
      environment: environment
   });
}

ReactDOM.render(<App/>, document.querySelector('#root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
