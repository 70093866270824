import {loadStripe} from "@stripe/stripe-js";
import * as moment from 'moment';
import {stripe_publishable_key} from "./utils";


export class Club {
   constructor({id, name, email, logo, stripe_account_id}) {
      this.id = id;
      this.name = name;
      this.email = email;
      this.logo = logo;
      this.stripe_account_id = stripe_account_id;
   }

   getStripe() {
      return loadStripe(stripe_publishable_key, {'stripeAccount': this.stripe_account_id});
   }
}

export class User {
   constructor({id, first_name, last_name, email, club, member_id, is_trusted}) {
      this.id = id;
      this.first_name = first_name;
      this.last_name = last_name;
      this.email = email;
      this.club = club;
      this.member_id = member_id;
      this.is_trusted = is_trusted;
   }

   getFullName() {
      return this.first_name + ' ' + this.last_name;
   }
}

export class Member {
   constructor({id, name, is_active, groups}) {
      this.id = id;
      this.name = name;
      this.is_active = is_active;
      this.groups = groups;
   }
}

export class Group {
   constructor({id, symbol, is_member_tag}) {
      this.id = id;
      this.symbol = symbol;
      this.is_member_tag = is_member_tag;
   }
}

export class Attendee {
   constructor({member_id, status, booked_at, cancelled_at, arrived_at, signee_id}) {
      this.member_id = member_id;
      this.status = status;
      if (booked_at) {
         this.booked_at = new Date(booked_at);
      } else {
         this.booked_at = null;
      }
      if (cancelled_at) {
         this.cancelled_at = new Date(cancelled_at);
      } else {
         this.cancelled_at = null;
      }
      if (arrived_at) {
         this.arrived_at = new Date(arrived_at);
      } else {
         this.arrived_at = null;
      }
      this.signee_id = signee_id;
   }
}

export class Venue {
   constructor({name, location}) {
      this.name = name;
      this.location = location;
   }
}

export class Event {
   constructor({id, name, description, image, venue, capacity, start_date, end_date, booking, booking_date, price, checkin_date, groups, attendees}) {
      // Remember to add any new properties to create() as well!
      this.id = id;
      this.name = name;
      this.description = description;
      this.image = image;
      this.venue = venue !== null ? new Venue(venue) : null;
      this.capacity = capacity;
      this.start_date = new Date(start_date);
      this.end_date = new Date(end_date);
      this.booking = booking;
      this.booking_date = new Date(booking_date);
      this.price = price;
      this.checkin_date = new Date(checkin_date);
      this.groups = groups;
      this.attendees = attendees.map(d => new Attendee(d));
   }

   static create({event, attendees}) {
      // TODO: proper generic factory
      return new Event({
         id: event.id,
         name: event.name,
         description: event.description,
         image: event.image,
         venue: event.venue,
         capacity: event.capacity,
         start_date: event.start_date,
         end_date: event.end_date,
         booking: event.booking,
         booking_date: event.booking_date,
         price: event.price,
         checkin_date: event.checkin_date,
         groups: event.groups,
         attendees: attendees,
      });
   }

   getAttendees(statuses) {
      return this.attendees.filter(a => statuses.find(s => s === a.status));
   }

   getBookedAttendees() {
      return this.attendees.filter(a => a.booked_at && !a.cancelled_at);
   }

   getCounter() {
      let count;
      if (this.booking && this.checkin_date > new Date()) {
         count = this.getAttendees(['BO', 'BG']).length;
      } else {
         count = this.getAttendees(['AT']).length;
      }
      if (this.capacity) {
         return count + '/' + this.capacity;
      } else {
         return count.toString();
      }

   }

   getOccupation() {
      if (!this.capacity) return null;
      let count;
      if (this.booking && this.checkin_date > new Date()) {
         count = this.getAttendees(['BO', 'BG']).length;
      } else {
         count = this.getAttendees(['AT']).length;
      }
      return count / this.capacity;
   }

   getDateLabel() {
      const now = new Date();
      if (now < this.checkin_date) {
         return moment(this.start_date).format('ddd Do MMM k:mm');
      } else if (now < this.start_date) {
         return moment(this.start_date).fromNow();
      } else if (now >= this.start_date && now <= this.end_date) {
         return 'Ongoing';
      } else {
         return moment(this.end_date).fromNow();
      }
   }

   getPriceToDisplay() {
      if (!this.price) return '';
      const price = this.price.toString();
      const [units, cents] = price.split('.');
      const amount = cents === '00' ? units : price;
      return `£${amount}`;
   }

   isFull() {
      if (!this.capacity) return false;
      return this.getAttendees(['BO', 'BG', 'AT']).length >= this.capacity;
   }

   bookingIsRequired() {
      return this.booking === 'RQ';
   }

   memberIsBooked(member) {
      return this.getBookedAttendees().find(a => a.member_id === member.id);
   }

   memberCanAttend(member) {
      if (this.groups.length === 0) return true;
      return this.groups.some(groupId => member.groups.find(gid => gid === groupId));
   }
}
