import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";


const useStyles = makeStyles({
   icon: {
      marginBottom: '-3px'
   }
});

const EventCardAction = (props) => {
   const {
      icon,
      iconColor = "secondary",
      label = "",
      buttonLabel = "",
      buttonVariant = "contained",
      buttonDisabled = false,
      buttonOnClick
   } = props;
   const Icon = icon;
   const classes = useStyles();

   return (
      <CardActions>
         <Grid container spacing={1} justify="space-between" alignItems="center" wrap="nowrap">
            <Grid item>
               <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item>
                     <Icon fontSize="small" color={iconColor} className={classes.icon}/>
                  </Grid>
                  <Grid item>
                     <Typography variant="body2" color="textSecondary" noWrap={true}>
                        {label}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item>
               {buttonLabel && (
                  <Button
                     variant={buttonVariant}
                     color="primary"
                     size="small"
                     disabled={buttonDisabled}
                     onClick={buttonOnClick}
                  >
                     {buttonLabel}
                  </Button>
               )}
            </Grid>
         </Grid>
      </CardActions>
   );
}

export default EventCardAction;
