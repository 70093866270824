import CircularProgress from "@material-ui/core/CircularProgress";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import TimerIcon from "@material-ui/icons/Timer";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import * as moment from "moment";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {checkinMember} from "../actions";
import {useAuth} from "../contexts/auth";
import {getDistanceBetweenPoints} from "../utils";
import ConfirmationDialog from "./ConfirmationDialog";
import EventCardAction from "./EventCardAction";


const EventCardActionCheckIn = ({event}) => {

   const {user} = useAuth();
   const dispatch = useDispatch();
   const [isCheckingIn, setIsCheckingIn] = useState(false);
   const [checkInErrorMessage, setCheckInErrorMessage] = useState(null);
   const now = new Date();

   const userBookedIn = event.getAttendees(['BO']).find(a => a.member_id === user.member_id);

   let icon, iconColor, label, buttonLabel;
   let buttonVariant = "contained";
   let buttonDisabled = false;
   if (userBookedIn) {
      if (event.end_date < now) {
         icon = EmojiPeopleIcon;
         label = "Did you attend?";
         buttonLabel = "Yes";
         buttonVariant = "outlined";
      } else {
         icon = EventAvailableIcon;
         iconColor = "primary";
         label = "You are booked in!";
         buttonLabel = "Check in";
      }
   } else {
      buttonLabel = "Check in";
      if (event.checkin_date < now) {
         icon = WifiTetheringIcon;
         label = "Check-in is open!";
      } else {
         icon = TimerIcon;
         iconColor = "disabled";
         label = "Opens " + moment(event.checkin_date).fromNow();
         buttonDisabled = true;
      }
   }

   if (isCheckingIn) {
      icon = WifiTetheringIcon;
      label = 'Checking in...';
      buttonLabel = <CircularProgress color="inherit" size="1.35rem"/>;
      buttonDisabled = true;
   }

   const buttonOnClick = async () => {
      setIsCheckingIn(true);

      if (event.end_date < now || event.venue === null) {
         await dispatch(checkinMember(event.id, user.member_id));
         setIsCheckingIn(false);
         return;
      }

      if (!navigator.geolocation) {
         setCheckInErrorMessage(`Your device does not support sharing your location.`);
         setIsCheckingIn(false);
         return;
      }

      navigator.geolocation.getCurrentPosition(
         async (position) => {
            const distance = getDistanceBetweenPoints(
               position.coords.latitude, position.coords.longitude,
               event.venue.location[0], event.venue.location[1]
            );
            if (distance < 300) {
               await dispatch(checkinMember(event.id, user.member_id));
            } else {
               setCheckInErrorMessage(`You are not close enough to the venue
                  to check in. Please get closer and try again.`);
            }
            setIsCheckingIn(false);
         },
         (error) => {
            let message = `There is a problem trying to verify your location.`;
            if (error.code === error.PERMISSION_DENIED) {
               message = `Your location cannot be verified if you don't allow your
                  browser to share your location. You can do this from your device
                  settings.`;
            } else if (error.code === error.POSITION_UNAVAILABLE) {
               message = `Your location can't be verified at the moment.`;
            } else if (error.code === error.TIMEOUT) {
               message = `It's taking too long to verify your location, make sure
                  you've got good signal and try again please.`;
            }
            setCheckInErrorMessage(message);
            setIsCheckingIn(false);
         },
         {enableHighAccuracy: true, maximumAge: 0}
      );
   };

   return (
      <div>
         {checkInErrorMessage && (
            <ConfirmationDialog
               title="You can't check in"
               text={[checkInErrorMessage, `Verifying your location is needed to
                  ensure you are at the venue at the time of checking in. Your
                  location is never tracked or stored.`]}
               confirmLabel="Ok"
               onConfirm={() => setCheckInErrorMessage(null)}
            />
         )}
         <EventCardAction icon={icon}
                          iconColor={iconColor}
                          label={label}
                          buttonLabel={buttonLabel}
                          buttonVariant={buttonVariant}
                          buttonDisabled={buttonDisabled}
                          buttonOnClick={buttonOnClick}/>
      </div>
   );
}

export default EventCardActionCheckIn;
