import List from '@material-ui/core/List';
import React, {useState} from 'react';
import AttendeeListItem from "./AttendeeListItem";
import ConfirmationDialog from "./ConfirmationDialog";

const AttendeesList = ({attendees, membersMap, capacity, onItemDeleted}) => {

   const [attendeeToBeDeleted, setAttendeeToBeDeleted] = useState(null);

   return (
      <div>
         {attendeeToBeDeleted !== null && (
            <ConfirmationDialog
            title={membersMap.get(attendeeToBeDeleted.member_id).name}
            text={["Are you sure you want to remove this member from the list of attendees?"]}
            confirmLabel="Yes"
            cancelLabel="No"
            onConfirm={() => {
               onItemDeleted(attendeeToBeDeleted);
               setAttendeeToBeDeleted(null);
            }}
            onCancel={() => setAttendeeToBeDeleted(null)}
         />)}
      <List dense={true}>
         {attendees.map((attendee, idx) =>
            <AttendeeListItem key={attendee.member_id}
                              attendee={attendee}
                              member={membersMap.get(attendee.member_id)}
                              itemNumber={attendees.length - idx}
                              capacity={capacity}
                              onDeleted={(attendee) => setAttendeeToBeDeleted(attendee)}/>
         )}
      </List>
      </div>
   );
}

export default AttendeesList;
