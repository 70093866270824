import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlaceIcon from '@material-ui/icons/Place';
import SentimentVeryDissatisfiedIcon
    from '@material-ui/icons/SentimentVeryDissatisfied';
import React from "react";
import {useSelector} from "react-redux";
import {useAuth} from "../contexts/auth";
import {
    getOccupationColour,
    SELF_BOOK_IN_ENABLED,
    SELF_CHECK_IN_ENABLED,
} from "../utils";
import EventCardAction from "./EventCardAction";
import EventCardActionBook from "./EventCardActionBook";
import EventCardActionCheckIn from "./EventCardActionCheckIn";
import EventCardActionPay from "./EventCardActionPay";
import EventCardActionUnbook from "./EventCardActionUnbook";
import EventCardActionUncheckIn from "./EventCardActionUncheckIn";
import EventCardActionWaitlist from "./EventCardActionWaitlist";

const useStyles = makeStyles(theme => ({
   MuiCard: {
      maxWidth: 304,
      margin: "auto",
      boxShadow: "0 0 20px 0 rgba(0,0,0,0.12)",
      "& .MuiCardMedia-root": {
         paddingTop: "56.25%",
         position: "relative",
         minHeight: '170px',  // needed for position: relative of avatar ::after to work
         "& .MuiTag--ribbon": {
            position: "absolute",
            top: theme.spacing(2),
            left: theme.spacing(2),
            backgroundColor: 'rgb(0,0,0,0.7)',
            color: "#ffffff !important",
            padding: "2px 8px",
            boxShadow: "0 2px 12px 2px rgba(0,0,0,0.5)",
            borderRadius: 2,
            "& .MuiTypography-root": {
               fontSize: '0.8rem',
               fontWeight: "bold"
            }
         },
         "& .MuiAvatar-root": {
            position: "absolute",
            right: "12%",
            bottom: 0,
            transform: "translateY(20%)",
            width: 48,
            height: 48,
            zIndex: 1,
            backgroundColor: props => getOccupationColour(props.event),
            fontSize: 15,
         },
         "&:after": {
            content: '" "',
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            borderBottom: "32px solid #ffffff",
            borderLeft: "400px solid transparent"
         }
      },
      "& .MuiCardContent-root": {
         textAlign: "left",
         padding: theme.spacing(3),
         "& h6": {
            display: 'block',
            width: '100%',
            overflow: 'hidden',
         },
         "& .MuiTags-root": {
            display: 'flex',
            flexWrap: 'nowrap',
            '& > *': {
               margin: theme.spacing(0.25),
               fontSize: '0.7rem'
            },
            marginTop: theme.spacing(1.50)
         },
      },
      "& .MuiTypography--heading": {
         fontWeight: "bold"
      },
      "& .MuiTypography--subheading": {
         lineHeight: 1.6
      },
      "& .MuiCardActions-root": {
         margin: theme.spacing(1),
      }
   },
   barRoot: {
      height: 1,
      backgroundColor: 'rgb(0, 0, 0, 0.12)',
   },
   barBar: {
      backgroundColor: 'rgb(0, 0, 0, 0.25)',
   },
}));

const EventCard = ({event, groupsMap, onCardSelected}) => {

   const {user} = useAuth();
   const classes = useStyles();
   const membersMap = useSelector(state => state.app.membersMap);
   const now = new Date();

   let action;
   const userCheckedIn = event.getAttendees(['AT']).find(
      a => a.member_id === user.member_id
   );

   if (userCheckedIn) {
      action = <EventCardActionUncheckIn event={event} attendee={userCheckedIn}/>;
   } else {
      const userBookedIn = event.getAttendees(['BO']).find(a => a.member_id === user.member_id);
      if (event.end_date < now) {
         if (userBookedIn) {
            if (SELF_CHECK_IN_ENABLED) {
               action = <EventCardActionCheckIn event={event}/>
            } else {
               action = (
                  <EventCardAction icon={SentimentVeryDissatisfiedIcon}
                                   iconColor="primary"
                                   label="You didn't attend"/>
               );
            }
         }
      } else {
         if (event.booking) {
            if (userBookedIn) {
               if (SELF_CHECK_IN_ENABLED && event.checkin_date < now) {
                  action = <EventCardActionCheckIn event={event}/>
               } else if (SELF_BOOK_IN_ENABLED && event.checkin_date > now) {
                  action = <EventCardActionUnbook event={event}/>
               }
            } else if (SELF_BOOK_IN_ENABLED) {
               if (event.getAttendees(['BG']).find(a => a.member_id === user.member_id)) {
                  action = <EventCardActionPay event={event}/>;
               } else if (event.isFull()) {
                  action = <EventCardActionWaitlist event={event}/>;
               } else {
                  action = <EventCardActionBook event={event}/>;
               }
            }
         } else if (SELF_CHECK_IN_ENABLED) {
            action = <EventCardActionCheckIn event={event}/>;
         }
      }
   }

   // TODO: do this properly!
   if (membersMap.size > 0) {
      const member = membersMap.get(user.member_id);
      if (!event.memberCanAttend(member)) {
         action = null;
      }
   }

   let counterContent = event.getCounter();
   if (user.is_trusted && event.checkin_date < now && event.getAttendees(['AT']).length === 0) {
      counterContent = <PersonAddIcon/>;
   }

   return (
      <div>
         <Card className={classes.MuiCard}>
            <CardMedia className={"MuiCardMedia-root"}
                       image={event.image ? event.image : '/static/event.png'}
                       onClick={onCardSelected}>
               <div className={"MuiTag--ribbon"}>
                  <Typography color={"inherit"}
                              className={"MuiTypography-root"}>
                     {event.getDateLabel().toUpperCase()}
                  </Typography>
               </div>
               <Avatar className={"MuiAvatar-root"}>
                  {counterContent}
               </Avatar>
            </CardMedia>
            <CardContent className={"MuiCardContent-root"}
                         onClick={onCardSelected}>
               <Typography className={"MuiTypography--heading"} variant={"h6"}
                           noWrap={true}>
                  {event.name}
               </Typography>
               {event.venue && (
                  <Grid container alignItems="center"
                        style={{marginBottom: 3}}>
                     <Grid item xs={1} style={{marginLeft: -3}}>
                        <PlaceIcon fontSize="small" color="disabled"
                                   style={{fontSize: '1rem'}}/>
                     </Grid>
                     <Grid item xs={11} style={{marginLeft: -3}}>
                        <Typography color="textSecondary"
                                    style={{fontSize: '0.75rem'}}
                                    gutterBottom noWrap={true}>
                           {event.venue.name}
                        </Typography>
                     </Grid>
                  </Grid>
               )}
               {event.description && (
                  <Typography className={"MuiTypography--subheading"}
                              variant={"caption"}>
                     {event.description}
                  </Typography>
               )}
               {false && event.groups.length > 0 && (
                  <div className={"MuiTags-root"}>
                     {event.groups.map(gid => <Chip size="small"
                                                    label={groupsMap.get(gid).symbol}/>)}
                  </div>
               )}
            </CardContent>
            {action && (
               <LinearProgress
                  variant="determinate"
                  value={event.getOccupation() !== null ? Math.min(event.getOccupation() * 100, 100) : 0}
                  classes={{
                     root: classes.barRoot,
                     bar: classes.barBar
                  }}
               />
            )}
            {action}
         </Card>
      </div>
   );
}

export default EventCard;
