import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {uncheckinMember} from "../actions";
import {useAuth} from "../contexts/auth";
import {SELF_CHECK_IN_ENABLED} from "../utils";
import ConfirmationDialog from "./ConfirmationDialog";
import EventCardAction from "./EventCardAction";


const EventCardActionUncheckIn = ({event, attendee}) => {

   const {user} = useAuth();
   const dispatch = useDispatch();
   const [isConfirmingUncheckIn, setIsConfirmingUncheckIn] = useState(false);
   const now = new Date();

   let buttonLabel, buttonVariant;
   const label = event.end_date < now ? "You attended" : "You are checked in";
   if (SELF_CHECK_IN_ENABLED && attendee.signee_id === user.id) {
      buttonLabel = 'Undo';
      buttonVariant = 'text';
   }

   return (
      <div>
         {isConfirmingUncheckIn && (
            <ConfirmationDialog
               title={event.name}
               text={["Do you want to remove yourself from the list of attendees?"]}
               confirmLabel="Yes"
               cancelLabel="No"
               onConfirm={() => {
                  dispatch(uncheckinMember(event.id, user.member_id));
                  setIsConfirmingUncheckIn(false);
               }}
               onCancel={() => setIsConfirmingUncheckIn(false)}
            />
         )}
         <EventCardAction icon={CheckCircleOutlineIcon}
                          iconColor="primary"
                          label={label}
                          buttonLabel={buttonLabel}
                          buttonVariant={buttonVariant}
                          buttonOnClick={() => setIsConfirmingUncheckIn(true)}/>
      </div>
   );
}

export default EventCardActionUncheckIn;
