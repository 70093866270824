import React, {useState} from 'react';
import {FixedSizeList} from 'react-window';
import SelectMemberListItem from "./SelectMemberListItem";

const SelectMemberList = ({members, onMemberSelected}) => {

   const [selectingId, setSelectingId] = useState(null);

   const itemData = members.reduce((map, obj, idx) => {
      const status = selectingId !== null && selectingId === obj.member.id ? 'selecting' : obj.status;
      map.set(idx, {
         member: obj.member,
         status: status,
         tags: obj.tags,
         disabled: selectingId !== null || status === "unselectable" || status === "selected",
         onClick: async (member) => {
            setSelectingId(member.id);
            await onMemberSelected(member);
            setSelectingId(null);
         }
      });
      return map;
   }, new Map());

   return (
      // 155 is an estimate of the height of AppBar + Search, can't get it dynamically :(
      <FixedSizeList
         height={window.innerHeight - 155} width="100%" itemSize={46}
         itemCount={members.length}
         itemData={itemData}
         itemKey={(index, data) => data.get(index).member.id}>
         {SelectMemberListItem}
      </FixedSizeList>
   );
}

export default SelectMemberList;
