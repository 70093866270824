import {Checkbox} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect} from "react-router";
import {requestLoginCode, signup} from '../actions';
import {useAuth} from "../contexts/auth";
import {privacyText} from "../docs/privacy";
import {termsText} from "../docs/terms";
import {isValidEmailAddress} from "../utils";
import {LayoutLoggedOut} from "./Layout";

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
}));


const SignupScene = ({location, history}) => {

   const dispatch = useDispatch();
   const {user} = useAuth();
   const classes = useStyles();
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const [tcAgreed, setTcAgreed] = useState(false);
   const [showingTerms, setShowingTerms] = useState(false);
   const [showingPrivacy, setShowingPrivacy] = useState(false);
   const [isSigningUp, setIsSigningUp] = useState(false);

   if (user) return <Redirect to='/'/>;

   const qs = queryString.parse(location.search);
   const club_id = qs.c;
   if (!club_id) {
      return <Redirect to='/'/>;
   }

   const getPaperDialog = (title, text, open, onClose) => (
      <Dialog
         open={open}
         onClose={onClose}
         scroll="paper"
      >
         <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
         <DialogContent dividers={true}>
            {text.split('\n').map((line) =>
               <DialogContentText
                  id="scroll-dialog-description"
                  variant="caption"
                  tabIndex={-1}
               >
                  {line}
               </DialogContentText>
            )}
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary">
               Ok
            </Button>
         </DialogActions>
      </Dialog>
   );

   let paperDialog;
   if (showingTerms) {
      paperDialog = getPaperDialog(
         "Terms and Conditions",
         termsText,
         showingTerms,
         () => setShowingTerms(false)
      );
   } else if (showingPrivacy) {
      paperDialog = getPaperDialog(
         "Privacy Policy",
         privacyText,
         showingPrivacy,
         () => setShowingPrivacy(false)
      );
   }

   return (
      <LayoutLoggedOut>
         <img src="/static/logo.svg" width="248" alt="Arrivaly"/>
         {paperDialog}
         <Typography
            variant="body2"
            align="center"
            style={{paddingTop: 20}}
            color="textSecondary"
         >
            {
               // TODO: dynamic club name
            }
            <strong>Out to Swim</strong> is inviting you to join their
            events.
         </Typography>
         <Typography
            variant="body2"
            align="center"
            style={{paddingTop: 10, paddingBottom: 10}}
            color="textSecondary"
         >
            Sign up below to start!
         </Typography>
         <form className={classes.form} noValidate>
            <Grid container spacing={2}>
               <Grid item xs={5}>
                  <TextField
                     variant="outlined"
                     fullWidth
                     id="first_name"
                     label="First name"
                     name="first_name"
                     autoComplete="given-name"
                     value={firstName}
                     onChange={(e) => setFirstName(e.target.value)}
                  />
               </Grid>
               <Grid item xs={7}>
                  <TextField
                     variant="outlined"
                     fullWidth
                     id="last_name"
                     label="Last name"
                     name="last_name"
                     autoComplete="family-name"
                     value={lastName}
                     onChange={(e) => setLastName(e.target.value)}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     type="email"
                     variant="outlined"
                     fullWidth
                     id="email"
                     label="Email address"
                     name="email"
                     autoComplete="email"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                  />
               </Grid>
               <Grid item xs={12}>
                  <FormControlLabel
                     control={<Checkbox value={tcAgreed}
                                        color="primary"/>}
                     onChange={() => setTcAgreed(!tcAgreed)}
                     label={
                        <Typography variant="caption"
                                    color="textSecondary">
                           I agree with the <Link
                           onClick={() => setShowingTerms(true)}>T&C</Link> and <Link
                           onClick={() => setShowingPrivacy(true)}>Privacy
                           Policy</Link>
                        </Typography>
                     }
                  />
               </Grid>
            </Grid>
            <Button
               type="submit"
               fullWidth
               variant="contained"
               color="primary"
               className={classes.submit}
               disabled={!firstName || !lastName || !isValidEmailAddress(email) || !tcAgreed || isSigningUp}
               onClick={async (e) => {
                  e.preventDefault();
                  setIsSigningUp(true);
                  if (await dispatch(signup(club_id, firstName, lastName, email))) {
                     const supportEmail = await dispatch(requestLoginCode(email));
                     // TODO: display error if login fails
                     if (supportEmail) {
                        history.push(`/login?e=${encodeURIComponent(email)}&s=${encodeURIComponent(supportEmail)}`)
                     }
                  }
                  setIsSigningUp(false);
               }}
            >
               {isSigningUp ? "Joining..." : "Sign Up"}
            </Button>
            <Grid container justify="flex-end">
               <Grid item>
                  <Link href="/login" variant="caption">
                     Already have an account? Sign in
                  </Link>
               </Grid>
            </Grid>
         </form>
      </LayoutLoggedOut>
   );
}

export default SignupScene;
