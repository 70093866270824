import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import {Event} from './models';
import {sortAttendees} from "./utils";

const INITIAL_STATE = {
   errorMessage: null,
   events: null,
   members: null,
   groupsMap: null,
};

function appReducer(state = INITIAL_STATE, action) {
   switch (action.type) {
      case 'RECEIVE_EVENTS':
         return {
            ...state,
            events: action.events,
            members: action.members,
            groupsMap: action.groups.reduce((map, group) => {
               map.set(group.id, group);
               return map;
            }, new Map()),
            membersMap: action.members.reduce((map, member) => {
               map.set(member.id, member);
               return map;
            }, new Map())
         };
      case 'UPDATE_ATTENDEE': {
         const events = state.events.map(event => {
            if (event.id !== action.eventId) {
               return event;
            }
            let attendees;
            if (event.attendees.find(a => a.member_id === action.attendee.member_id)) {
               // Update the attendee
               attendees = event.attendees.map(attendee => {
                  if (attendee.member_id !== action.attendee.member_id) {
                     return attendee;
                  }
                  return action.attendee;
               });
            } else {
               // Add the attendee
               attendees = [action.attendee, ...event.attendees];
            }
            attendees = sortAttendees(attendees);
            return Event.create({
               event: event,
               attendees: attendees
            });
         });
         return {...state, events: events};
      }
      case 'DELETE_ATTENDEE': {
         const events = state.events.map(event => {
            if (event.id !== action.eventId) {
               return event;
            }
            let attendees;
            attendees = event.attendees.filter(
               attendee => attendee.member_id !== action.memberId
            );
            attendees = sortAttendees(attendees);
            return Event.create({event: event, attendees: attendees});
         });
         return {...state, events: events};
      }
      case 'DISPLAY_ERROR': {
         return {...state, errorMessage: action.message};
      }
      case 'CLEAR_ERROR': {
         return {...state, errorMessage: null};
      }
      default:
         return state;
   }
}

function createRootReducer(history) {
   return combineReducers({
      router: connectRouter(history),
      app: appReducer
   });
}

export default createRootReducer;
