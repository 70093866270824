import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import {lighten} from '@material-ui/core/styles';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from "react-router";
import {checkinMember, refreshData, uncheckinMember} from '../actions';
import AttendeesList from './AttendeesList';
import {useAuth} from "../contexts/auth";
import {getOccupationColour} from '../utils';
import {LayoutLoggedIn} from "./Layout";
import SelectMember from './SelectMember';

const useStyles = makeStyles(theme => ({
   fab: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed'
   },
   barRoot: {
      height: 10,
      backgroundColor: props => lighten(getOccupationColour(props.event), 0.5),
      marginTop: 7
   },
   barBar: {
      backgroundColor: props => getOccupationColour(props.event)
   },
   MuiCard: {
      margin: "auto",
      boxShadow: "0 0 20px 0 rgba(0,0,0,0.12)",
      "& .MuiCardMedia-root": {
         position: "relative",
         minHeight: '120px',
         "& .MuiTag--ribbon": {
            position: "absolute",
            top: theme.spacing(1),
            left: theme.spacing(1),
            backgroundColor: 'rgb(0,0,0,0.7)',
            color: "#ffffff !important",
            padding: "2px 8px",
            boxShadow: "0 2px 12px 2px rgba(0,0,0,0.5)",
            borderRadius: 2,
            "& .MuiTypography-root": {
               fontSize: '0.8rem',
               fontWeight: "bold"
            }
         },
         "& .MuiTags-root": {
            display: 'flex',
            flexWrap: 'nowrap',
            '& > *': {
               margin: theme.spacing(0.25),
               backgroundColor: 'rgb(217, 217, 217, 0.7)'
            },
            position: 'absolute',
            bottom: theme.spacing(1),
            left: theme.spacing(1),
         },
      },
   },
   avatar: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      width: 48,
      height: 48,
      zIndex: 1,
      backgroundColor: props => getOccupationColour(props.event),
      fontSize: 15,
   },
}));


const EventScene = ({match, history}) => {

   const {user} = useAuth();
   const events = useSelector(state => state.app.events || []);
   const event = events.find(e => e.id === match.params.id);
   const members = useSelector(state => state.app.members);
   const membersMap = useSelector(state => state.app.membersMap);
   const groupsMap = useSelector(state => state.app.groupsMap);
   const classes = useStyles();
   const [isLoading, setIsLoading] = useState(false);
   const [isSelectingMember, setIsSelectingMember] = useState(false);
   const dispatch = useDispatch();

   if (!user) return <Redirect to='/'/>;
   if (!user.is_trusted) return <Redirect to='/events'/>;

   const refreshEvents = () => {
      setIsLoading(true);
      dispatch(refreshData()).then(() => setIsLoading(false));
   };

   if (!event || isLoading) {
      if (!isLoading) {
         refreshEvents();
      }
      return (
         <LayoutLoggedIn title="" onIconClicked={history.goBack} m={3} align="center">
            <CircularProgress/>
         </LayoutLoggedIn>
      );
   }

   const bookedAttendees = event.getBookedAttendees();
   const cancelledAttendees = event.getAttendees(['CA']);
   const attendees = event.getAttendees(['AT']);
   let selectedMemberIds = attendees.map(a => a.member_id);

   let canSelectMembers = false;
   let membersToSelect = [];
   members.forEach(member => {
      if (member.is_active) {
         let status = "selected";
         if (!event.memberCanAttend(member) || (event.bookingIsRequired() && !event.memberIsBooked(member))) {
            status = "unselectable";
         } else if (!selectedMemberIds.includes(member.id)) {
            status = "selectable";
            canSelectMembers = true;
         }
         let tags = member.groups.reduce((tags, group_id) => {
            let group = groupsMap.get(group_id);
            if (group !== undefined && group.is_member_tag) {
               tags.push(group.symbol);
            }
            return tags;
         }, []);
         const booked = bookedAttendees.find(a => a.member_id === member.id);
         const cancelled = cancelledAttendees.find(a => a.member_id === member.id);
         if (booked) {
            tags.push('BOOKED');
         } else if (cancelled) {
            tags.push('CANCELLED');
         }
         membersToSelect.push({
            member: member,
            status: status,
            tags: tags,
            booked: booked !== undefined,
            cancelled: cancelled !== undefined,
         });
      }
   });

   if (isSelectingMember) {
      return (
         <SelectMember
            members={membersToSelect}
            onMemberSelected={async (member) => {
               const success = await dispatch(checkinMember(event.id, member.id));
               if (success) {
                  setIsSelectingMember(false);
               }
               return success;
            }}
            onCancel={() => setIsSelectingMember(false)}
         />
      );
   }

   return (
      <LayoutLoggedIn title={event.name} onIconClicked={history.goBack} onRefreshClick={refreshEvents} p={1}>
         <Box>
            <Card className={classes.MuiCard}>
               <CardMedia className={"MuiCardMedia-root"}
                          image={event.image ? event.image : "/static/event.png"}>
                  <div className={"MuiTag--ribbon"}>
                     <Typography color={"inherit"}
                                 className={"MuiTypography-root"}>
                        {event.getDateLabel().toUpperCase()}
                     </Typography>
                  </div>
                  <Avatar className={classes.avatar}>
                     {event.getCounter()}
                  </Avatar>
                  {false && event.groups.length > 0 && (
                     <div className={"MuiTags-root"}>
                        {event.groups.map(gid => <Chip size="small"
                                                       label={groupsMap.get(gid).symbol}/>)}
                     </div>
                  )}
               </CardMedia>
            </Card>
            {event.capacity && (
               <LinearProgress
                  variant="determinate"
                  value={Math.min(event.getOccupation() * 100, 100)}
                  classes={{
                     root: classes.barRoot,
                     bar: classes.barBar
                  }}
               />
            )}
         </Box>
         {(attendees.length > 0 && (
            <AttendeesList
               attendees={attendees}
               membersMap={membersMap}
               capacity={event.capacity}
               onItemDeleted={a => dispatch(uncheckinMember(event.id, a.member_id))}
            />
         )) || (
            <Box padding={3}>
               <Typography align="center" variant="body2"
                           color="textSecondary"
                           paragraph={true}>
                  No members checked in yet.
               </Typography>
               <Typography align="center" variant="body2"
                           color="textSecondary">
                  Use the bottom right + button to start.
               </Typography>
            </Box>
         )}
         {canSelectMembers && (
            <Box align="right">
               <Fab
                  color="primary"
                  aria-label="add"
                  align="center"
                  className={classes.fab}
                  onClick={() => setIsSelectingMember(true)}
               >
                  <PersonAddIcon/>
               </Fab>
            </Box>
         )}
      </LayoutLoggedIn>
   );
}

export default EventScene;
