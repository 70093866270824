import React from 'react';
import {Redirect} from "react-router";
import {useAuth} from "../contexts/auth";

const HomeScene = () => {
   const {user} = useAuth();
   return user ? <Redirect to='/events'/> : <Redirect to='/login'/>
};

export default HomeScene;
