import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import PersonIcon from "@material-ui/icons/Person";
import moment from "moment";
import React, {memo} from "react";

const AttendeeListItem = ({attendee, member, itemNumber, capacity, onDeleted}) => {

   var icon;
   if (!capacity || itemNumber <= capacity) {
      icon = <PersonIcon fontSize="large"/>;
   } else {
      icon = <NewReleasesIcon fontSize="large" style={{color: '#f50057'}}/>;
   }

   return (
      <ListItem>
         <ListItemAvatar>
            {icon}
         </ListItemAvatar>
         <ListItemText
            primary={member.name}
            secondary={moment(attendee.arrived_at).fromNow()}
         />
         <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete"
                        onClick={() => onDeleted(attendee)}>
               <DeleteIcon/>
            </IconButton>
         </ListItemSecondaryAction>
      </ListItem>
   );
}

export default memo(AttendeeListItem);
