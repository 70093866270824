import { StatusCodes } from "http-status-codes";
import decode from "jwt-decode";
import { environment } from "./utils";

class Api {
  constructor(url) {
    this.url = url;
  }

  hasToken() {
    const token = this.getToken();
    return !!token && !this.tokenHasExpired(token);
  }

  tokenHasExpired(token) {
    try {
      const decoded = decode(token);
      return decoded.exp < Date.now() / 1000;
    } catch (err) {
      return false;
    }
  }

  setToken(token) {
    localStorage.setItem("token", token);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  logout() {
    localStorage.removeItem("token");
  }

  async fetch(path, options = {}) {
    options["headers"] = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (this.hasToken()) {
      options["headers"]["Authorization"] = "Bearer " + this.getToken();
    }

    try {
      var response = await fetch(`${this.url}${path}`, options);
    } catch (error) {
      return { data: null, status: StatusCodes.INTERNAL_SERVER_ERROR };
    }

    let data = "";
    if (
      response.status !== StatusCodes.NO_CONTENT &&
      response.status !== StatusCodes.PAYMENT_REQUIRED &&
      response.status < 499
    ) {
      data = await response.json();
    }

    return {
      data: data,
      status: response.status,
    };
  }
}

export function findAPIError(data) {
  // Returns the first error found in an API response payload
  if (data.constructor === Array) {
    // Plain errors returned by ValidationError
    return data[0];
  } else if (typeof data === "object") {
    // Django Rest Framework serializer errors
    const keys = Object.keys(data);
    return data[keys[0]][0];
  }
}

let url = "http://localhost:8010/api";
if (environment === "production") {
  url = "https://arrivaly.club/api";
} else if (environment === "staging") {
  url = "https://test.arrivaly.club/api";
}

export default new Api(url);
