import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {StatusCodes} from "http-status-codes";
import React, {useState} from 'react';
import api from "../api";
import {LayoutLoggedOut} from "../components/Layout";
import {Club, User} from "../models";


const AuthContext = React.createContext({
   user: null, login: () => {}, logout: () => {}
});

function AuthProvider(props) {

   const getMe = async () => {
      let user = null;
      const {data, status} = await api.fetch('/me');

      // TODO: if error, display it
      if (status === StatusCodes.OK) {
         user = new User({
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            club: new Club({
               id: data.club.id,
               name: data.club.name,
               email: data.club.email,
               logo: data.club.logo,
               stripe_account_id: data.club.stripe_account_id
            }),
            member_id: data.member_id,
            is_trusted: data.is_trusted,
         });
      }
      return user;
   };

   // user == obj => logged in
   // user == null => logged out
   // user == undefined => might be able to getMe()
   const [user, setUser] = useState()

   if (user === undefined) {
      getMe().then(u => setUser(u));
      return (
         <LayoutLoggedOut>
            <CircularProgress/>
         </LayoutLoggedOut>
      );
   }

   const login = async (email, code) => {
      const {data, status} = await api.fetch('/login', {
         method: 'POST',
         body: JSON.stringify({email: email, code: code})
      });
      if (status !== StatusCodes.OK) {
         // TODO: show error
         return false;
      }
      api.setToken(data.token);
      setUser(await getMe());
      return true;
   };

   const logout = () => {
      api.logout();
      setUser(null);
   }

   return (
      <AuthContext.Provider value={{user, login, logout}} {...props} />
   );
}

const useAuth = () => React.useContext(AuthContext);

export {AuthContext, AuthProvider, useAuth};
