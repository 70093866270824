import {Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from 'react';
import {Redirect} from "react-router";
import {useAuth} from "../contexts/auth";
import {LayoutLoggedIn} from "./Layout";

const useStyles = makeStyles(theme => ({
   media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
   },
   name: {
      marginBottom: 0,
   },
   email: {
      marginBottom: 20,
   }
}));

const AccountScene = () => {

   const {user} = useAuth();
   const classes = useStyles();

   if (!user) return <Redirect to='/'/>;

   return (
      <LayoutLoggedIn title="Account" p={3}>
         <Card className={classes.root}>
            <CardActionArea>
               <CardMedia
                  className={classes.media}
                  image={user.club.logo ? user.club.logo : "/static/event.png"}
                  title={user.club.email + " logo"}
               />
               <CardContent>
                  <Typography gutterBottom variant="subtitle1"
                              component="h2"
                              className={classes.name}>
                     {user.getFullName()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary"
                              component="p"
                              className={classes.email}>
                     {user.email}
                  </Typography>
                  <Typography variant="body2" color="textSecondary"
                              component="p">
                     For any queries on your account, please
                     contact <Link
                     href={"mailto:" + user.club.email}>{user.club.email}</Link>
                  </Typography>
               </CardContent>
            </CardActionArea>
         </Card>
      </LayoutLoggedIn>
   );
}

export default AccountScene;
