import {ErrorOutline} from "@material-ui/icons";
import React from "react";
import {useDispatch} from "react-redux";
import {payEvent} from "../actions";
import {useAuth} from "../contexts/auth";
import EventCardAction from "./EventCardAction";


const EventCardActionPay = ({event}) => {

   const {user} = useAuth();
   const dispatch = useDispatch();

   return (
      <EventCardAction icon={ErrorOutline}
                       label="Awaiting payment"
                       buttonLabel="Pay"
                       buttonOnClick={() => dispatch(payEvent(event.id, user))}/>
   );
}

export default EventCardActionPay;
