import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, {useState} from 'react';

import {Link} from "react-router-dom";
import {useAuth} from "../contexts/auth";

const useStyles = makeStyles(theme => ({
   title: {
      flexGrow: 1
   },
   pushdown: theme.mixins.toolbar, // pushes sibblings down
   list: {
      width: 200
   },
   listItemIcon: {
      minWidth: 38
   }
}));

const Bar = ({title, onIconClicked, onRefreshClick}) => {

   const {user, logout} = useAuth();
   const classes = useStyles();
   const [drawer, setDrawer] = useState(false);

   var icon;
   if (onIconClicked) {
      icon = <ArrowBackIosIcon/>;
   } else {
      icon = <MenuIcon/>;
   }

   const toggleDrawer = open => event => {
      if (
         event.type === 'keydown' &&
         (event.key === 'Tab' || event.key === 'Shift')
      ) {
         return;
      }
      setDrawer(open);
   };

   const userName = user ? user.first_name : '...';

   const sideList = () => (
      <div
         className={classes.list}
         onClick={toggleDrawer(false)}
         onKeyDown={toggleDrawer(false)}
      >
         <List>
            <ListItem button key="account" component={Link} to="/account">
               <ListItemIcon className={classes.listItemIcon}>
                  <AccountCircleIcon/>
               </ListItemIcon>
               <ListItemText primary={userName}/>
            </ListItem>
            <Divider/>
            <ListItem button key="events" component={Link} to="/events">
               <ListItemIcon className={classes.listItemIcon}>
                  <EventIcon/>
               </ListItemIcon>
               <ListItemText primary="Events"/>
            </ListItem>
            <ListItem button key="help" component={Link} to="/help">
               <ListItemIcon className={classes.listItemIcon}>
                  <ContactSupportIcon/>
               </ListItemIcon>
               <ListItemText primary="Help"/>
            </ListItem>
            <ListItem button key="logout" onClick={logout}>
               <ListItemIcon className={classes.listItemIcon}>
                  <ExitToAppIcon/>
               </ListItemIcon>
               <ListItemText primary="Sign out"/>
            </ListItem>
         </List>
      </div>
   );

   return (
      <div>
         {!onIconClicked && (
            <Drawer open={drawer} onClose={toggleDrawer(false)}>
               {sideList()}
            </Drawer>
         )}
         <AppBar>
            <Toolbar>
               <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={onIconClicked ? onIconClicked : toggleDrawer(true)}
               >
                  {icon}
               </IconButton>
               <Typography variant="h6" className={classes.title}
                           noWrap={true}>
                  {title}
               </Typography>
               {onRefreshClick && (
                  <div>
                     <IconButton
                        aria-label="refresh page"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={onRefreshClick}
                        color="inherit"
                     >
                        <RefreshIcon/>
                     </IconButton>
                  </div>
               )}
            </Toolbar>
         </AppBar>
         <div className={classes.pushdown}/>
      </div>
   );
}

export default Bar;
