export function sleep(ms) {
   return new Promise(resolve => setTimeout(resolve, ms));
}

function getEnvironment() {
   // Hackish on runtime to have a stateless promotable build
   switch (window.location.hostname) {
      case 'arrivaly.app':
      case 'www.arrivaly.app':
         return 'production';
      case 'test.arrivaly.app':
         return 'staging';
      default:
         return 'development';
   }
}

export const environment = getEnvironment();

// Feature flags
export let SELF_CHECK_IN_ENABLED;
export let SELF_BOOK_IN_ENABLED;
if (environment === 'production') {
   SELF_CHECK_IN_ENABLED = true;
   SELF_BOOK_IN_ENABLED = true;
} else if (environment === 'staging') {
   SELF_CHECK_IN_ENABLED = true;
   SELF_BOOK_IN_ENABLED = true;
} else {
   SELF_CHECK_IN_ENABLED = true;
   SELF_BOOK_IN_ENABLED = true;
}

export let stripe_publishable_key = 'pk_test_51HLs69Ic3ak2iSRzcj75dCkWjJ1EcGKfpcDN07A8BveFwOQVJDPnClsFJnhoCyb7UzW9tmysHSSfBLjrPUgZWxMG00NO2tNNz2';
if (environment === 'production') {
   stripe_publishable_key = 'pk_live_51HLs69Ic3ak2iSRzqRVx40uKlVBG13gNnGXHct250XhxRE6imwitbyXzGurJL7BSN27rOut2U73GHwUt4v0uRFId00rLQq3eSK';
}

export function getOccupationColour(event) {
   return '#f50057';
}

export function sortAttendees(attendees) {
   attendees.sort((a1, a2) => {
      // First by -arrived_at (desc)
      if (a1.arrived_at > a2.arrived_at) return -1;
      if (a1.arrived_at < a2.arrived_at) return 1;
      // Second by booked_at (asc)
      if (a1.booked_at > a2.booked_at) return 1;
      if (a1.booked_at < a2.booked_at) return -1;
   });
   return attendees;
}

export function getDistanceBetweenPoints(lat1, lon1, lat2, lon2) {
   var R = 6371 * 1000; // Radius of the earth in m
   var dLat = deg2rad(lat2 - lat1);
   var dLon = deg2rad(lon2 - lon1);
   var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
   ;
   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   var d = R * c;
   return d;
}

function deg2rad(deg) {
   return deg * (Math.PI / 180);
}

export function getOrdinal(n) {
   var s = ["th", "st", "nd", "rd"];
   var v = n % 100;
   return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function isValidEmailAddress(email) {
   const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(email);
}

export function isValidCode(code) {
   const re = /^\d{6}$/;
   return re.test(code);
}
