import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Bar from "./Bar";


const LayoutLoggedIn = (props) => {
   return (
      <div>
         <Bar title={props.title} onIconClicked={props.onIconClicked} onRefreshClick={props.onRefreshClick}/>
         <Box {...props}>
            {props.children}
         </Box>
      </div>
   );
}

const useStyles = makeStyles(theme => ({
   paper: {
      marginTop: theme.spacing(10),
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   }
}));

const LayoutLoggedOut = (props) => {
   const classes = useStyles();
   return (
      <Container component="main" maxWidth="xs">
         <div className={classes.paper}>
            {props.children}
         </div>
      </Container>
   );
}

export {LayoutLoggedIn, LayoutLoggedOut};
