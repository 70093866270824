import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {unbookMember} from "../actions";
import {useAuth} from "../contexts/auth";
import ConfirmationDialog from "./ConfirmationDialog";
import EventCardAction from "./EventCardAction";


const EventCardActionUnbook = ({event}) => {

   const {user} = useAuth();
   const dispatch = useDispatch();
   const [isConfirmingUnbook, setIsConfirmingUnbook] = useState(false);

   return (
      <div>
         {isConfirmingUnbook && (
            <ConfirmationDialog
               title="Cancel booking"
               text={[
                  event.price ? (
                     "If you cancel your booking you will not get a refund and your " +
                     "spot will be released for anyone else to book."
                  ) : (
                     "If you cancel your booking your spot will be released for " +
                     "anyone else to book."
                  ),
                  "Do you want to proceed?",
               ]}
               confirmLabel="Yes"
               cancelLabel="No"
               onConfirm={() => {
                  dispatch(unbookMember(event.id, user.member_id));
                  setIsConfirmingUnbook(false);
               }}
               onCancel={() => setIsConfirmingUnbook(false)}
            />
         )}
         <EventCardAction icon={EventAvailableIcon}
                          label="You are booked in!"
                          buttonLabel="Cancel"
                          buttonVariant="text"
                          buttonOnClick={() => setIsConfirmingUnbook(true)}/>
      </div>
   );
}

export default EventCardActionUnbook;
