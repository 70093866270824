import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from '@material-ui/core/Typography';
import AlarmIcon from '@material-ui/icons/Alarm';
import EventIcon from '@material-ui/icons/Event';
import HistoryIcon from '@material-ui/icons/History';
import React, {createRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";
import {refreshData} from "../actions";
import EventCard from "./EventCard";
import {useAuth} from "../contexts/auth";
import {LayoutLoggedIn} from "./Layout";

const queryString = require('query-string');

const EventsScene = ({history, location}) => {

   const {user} = useAuth();
   const [isLoading, setIsLoading] = useState(false);
   const dispatch = useDispatch();
   const events = useSelector(state => state.app.events);
   const groupsMap = useSelector(state => state.app.groupsMap);

   const focusEventId = location.hash ? location.hash.substring(4) : null; // #id=
   let eventRefs = new Map();

   useEffect(() => {
      if (focusEventId) {
         setTimeout(() => {
            const focusEventRef = eventRefs.get(focusEventId);
            // if the timeout was not long enough the ref would not be ready
            if (focusEventRef && focusEventRef.current) {
               window.scrollTo({
                  behavior: "smooth",
                  top: focusEventRef.current.offsetTop - 80, // app bar height
               });
               history.push({hash: ''});
            }
         }, 1200);
      }
   });

   if (!user) return <Redirect to='/'/>;

   const refreshEvents = () => {
      setIsLoading(true);
      dispatch(refreshData()).then(() => setIsLoading(false));
   };

   if (events === null || isLoading) {
      if (!isLoading) {
         refreshEvents();
      }
      return (
         <LayoutLoggedIn title="Events" m={3} align="center">
            <CircularProgress/>
         </LayoutLoggedIn>
      );
   }

   const now = new Date();
   const oneHourAgo = new Date(now.getTime() - 1 * 60 * 60000);

   // Recent: anything up to 1 hour ago
   let recentEvents = events.filter(e => e.end_date <= oneHourAgo);
   recentEvents.sort((e1, e2) => e2.start_date - e1.start_date);

   // Now: anything more recent than 1 hour ago with check in open
   let nowEvents = events.filter(
      e => e.end_date > oneHourAgo && e.checkin_date <= now
   );
   nowEvents.sort((e1, e2) => e2.start_date - e1.start_date);

   // Anything with check in not yet open
   let upcomingEvents = events.filter(e => e.checkin_date > now);
   upcomingEvents.sort((e1, e2) => e1.start_date - e2.start_date);

   let currentTab;
   let tabEvents;

   if (focusEventId) {
      if (upcomingEvents.find(e => e.id === focusEventId)) {
         currentTab = 2;
         tabEvents = upcomingEvents;
      } else if (nowEvents.find(e => e.id === focusEventId)) {
         currentTab = 1;
         tabEvents = nowEvents;
      } else if (recentEvents.find(e => e.id === focusEventId)) {
         currentTab = 0;
         tabEvents = recentEvents;
      }
      if (tabEvents !== undefined) {
         tabEvents.forEach(e => eventRefs.set(e.id, createRef()));
      }
   }

   const tabQueryParams = ["recent", "now", "upcoming"];
   if (currentTab === undefined) {
      const qs = queryString.parse(location.search)
      if (qs.v) {
         const tabIndex = tabQueryParams.findIndex(value => value === qs.v);
         if (tabIndex !== -1) {
            currentTab = tabIndex;
         }
      }
   }

   if (currentTab === undefined) {
      if (nowEvents.length > 0) {
         currentTab = 1;
      } else if (upcomingEvents.length > 0) {
         currentTab = 2;
      } else if (recentEvents.length > 0) {
         currentTab = 0;
      } else {
         currentTab = 1;
      }
   }

   if (tabEvents === undefined) {
      if (currentTab === 0) {
         tabEvents = recentEvents;
      } else if (currentTab === 1) {
         tabEvents = nowEvents;
      } else if (currentTab === 2) {
         tabEvents = upcomingEvents;
      }
   }

   let tabStyles = {fontSize: '0.775rem'};

   return (
      <LayoutLoggedIn title="Events" onRefreshClick={refreshEvents} pt={0.5}>
         <Tabs
            value={currentTab}
            onChange={(e, value) => history.push('/events?v=' + tabQueryParams[value])}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="disabled tabs example"
            style={{marginBottom: 20}}
         >
            <Tab label="Recent" icon={<HistoryIcon/>} style={tabStyles}/>
            <Tab label="Now" icon={<AlarmIcon/>} style={tabStyles}/>
            <Tab label="Upcoming" icon={<EventIcon/>} style={tabStyles}/>
         </Tabs>
         {tabEvents.length > 0 ? (
            <Grid container direction="column" justify="flex-start">
               {tabEvents.map(event => { // Sync with eventRefs code above!
                  return (
                     <Grid item key={event.id} style={{marginBottom: 30}}
                           ref={eventRefs.get(event.id)}>
                        <EventCard
                           event={event}
                           groupsMap={groupsMap}
                           onCardSelected={() => {
                              if (user.is_trusted && event.checkin_date < new Date()) {
                                 history.push('/events/' + event.id);
                              }

                           }}
                        />
                     </Grid>
                  );
               })}
            </Grid>
         ) : (
            <Typography
               variant="body1"
               color="textSecondary"
               align="center"
               style={{marginTop: '40px'}}
            >
               {currentTab === 0 ? (
                  "No recent events."
               ) : currentTab === 1 ? (
                  "No ongoing events."
               ) : currentTab === 2 ? (
                  "No upcoming events."
               ) : (
                  ""
               )}
            </Typography>
         )}
      </LayoutLoggedIn>
   );
}

export default EventsScene;
