import * as Sentry from '@sentry/browser';
import {StatusCodes} from "http-status-codes";
import api, {findAPIError} from './api';
import {Attendee, Event, Group, Member} from './models';
import {SELF_BOOK_IN_ENABLED} from "./utils";


/*
 * ACTION CREATORS
 */

const receiveData = (events, members, groups) => ({
   type: 'RECEIVE_EVENTS',
   events: events, //json.data.children.map(child => child.data),
   members: members,
   groups: groups
});

const updateAttendee = (eventId, attendee) => ({
   type: 'UPDATE_ATTENDEE',
   eventId: eventId,
   attendee: attendee
});

export const deleteAttendee = (eventId, memberId, attendee) => ({
   type: 'DELETE_ATTENDEE',
   eventId: eventId,
   memberId: memberId,
   attendee: attendee
});

const displayError = message => ({
   type: 'DISPLAY_ERROR',
   message: message
});

export const clearError = () => ({
   type: 'CLEAR_ERROR'
});

/*
 * THUNKS
 */

export function signup(clubId, firstName, lastName, email) {
   return async (dispatch, getState) => {
      const {data, status} = await api.fetch('/signup', {
         method: 'post',
         body: JSON.stringify({
            club_id: clubId,
            first_name: firstName,
            last_name: lastName,
            email: email
         })
      });

      if (status !== StatusCodes.OK) {
         if (data) {
            const error = findAPIError(data);
            if (error) {
               dispatch(displayError(error));
            }
         }
         return false;
      }

      return true;
   };
}

export function requestLoginCode(email) {
   return async (dispatch, getState) => {
      const {data, status} = await api.fetch('/code', {
         method: 'post',
         body: JSON.stringify({email: email})
      });

      if (status !== StatusCodes.OK) {
         if (data) {
            const error = findAPIError(data);
            if (error) {
               dispatch(displayError(error));
            }
         }
         return null;
      }

      return data.support_email;
   };
}

export function refreshData() {
   return async dispatch => {
      // from last seven days (full days) to tomorrow (full day)
      const now = new Date();
      let fromDate = new Date(now.getTime());
      fromDate.setDate(fromDate.getDate() - 7);
      fromDate.setHours(0, 0, 0, 0);
      const fromISO = fromDate.toISOString();
      // If we wanted events only to show up within the check-in window:
      // let toDate = new Date(now.getTime() + 30 * 60 * 1000);  // 30 mins in ms
      let toDate = new Date(now.getTime());
      if (SELF_BOOK_IN_ENABLED) {
         toDate.setDate(now.getDate() + 21);
      } else {
         toDate.setDate(now.getDate() + 1);
      }
      toDate.setHours(23, 59, 59, 999);
      const toISO = toDate.toISOString();

      const values = await Promise.all([
         api.fetch('/members/'),
         api.fetch('/groups/'),
         api.fetch(`/events/?from=${fromISO}&to=${toISO}`)
      ]);

      if (values.some(v => v.status !== StatusCodes.OK)) {
         return;
      }

      const events = values[2].data.map(d => new Event(d));
      const groups = values[1].data.map(d => new Group(d));
      const members = values[0].data.map(d => new Member(d));
      dispatch(receiveData(events, members, groups));
   };
}

export function bookMember(eventId, user) {
   return async dispatch => {
      const {data, status} = await api.fetch(
         `/events/${eventId}/book`, {method: 'post'}
      );

      if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
         dispatch(displayError(""));
      }

      if (status === StatusCodes.OK) {
         if (data.member_id) {
            dispatch(updateAttendee(eventId, new Attendee(data)));
         } else {
            dispatch(refreshData()); // TODO: Smooth refresh
         }
      } else if (status === StatusCodes.PAYMENT_REQUIRED) {
         const {data, status} = await api.fetch(`/events/${eventId}/pay`, {method: 'post'});
         if (status === StatusCodes.OK) {
            if (data.id) {
               const stripe = await user.club.getStripe();
               const stripeResult = await stripe.redirectToCheckout({sessionId: data.id});
               if (stripeResult.error) {
                  showError(stripeResult.error.message);
               }
            } else {
               dispatch(refreshData()); // TODO: Smooth refresh
            }
         }
      }
   };
}

export function payEvent(eventId, user) {
   return async (dispatch, getState) => {
      const {data, status} = await api.fetch(`/events/${eventId}/pay`, {method: 'post'});
      if (status === StatusCodes.OK) {
         if (data.id) {
            const stripe = await user.club.getStripe();
            const stripeResult = await stripe.redirectToCheckout({sessionId: data.id});
            if (stripeResult.error) {
               showError(stripeResult.error.message);
            }
         } else {
            dispatch(refreshData()); // TODO: Smooth refresh
         }
      }
   };
}

export function unbookMember(eventId, memberId) {
   return async dispatch => {
      const {data, status} = await api.fetch(`/events/${eventId}/unbook`, {method: 'post'});
      if (status === StatusCodes.OK) {
         if (data.member_id) {
            dispatch(updateAttendee(eventId, new Attendee(data)));
         } else {
            dispatch(deleteAttendee(eventId, memberId));
         }
      }
   };
}

export function waitlist(eventId, memberId, join) {
   return async (dispatch, getState) => {
      const {data, status} = await api.fetch(`/events/${eventId}/waitlist`, {
         method: join ? 'post' : 'delete',
      });
      if (status === StatusCodes.OK) {
         if (data.member_id) {
            dispatch(updateAttendee(eventId, new Attendee(data)));
         } else {
            dispatch(deleteAttendee(eventId, memberId));
         }
      }
   };
}

export function checkinMember(eventId, memberId) {
   return async (dispatch, getState) => {
      const {data, status} = await api.fetch(`/events/${eventId}/checkin`, {
         method: 'post',
         body: JSON.stringify({member_id: memberId})
      });

      if (status !== StatusCodes.OK) {
         if (data) {
            const error = findAPIError(data);
            if (error) {
               dispatch(displayError(error));
            }
         }
         return false;
      }

      dispatch(updateAttendee(eventId, new Attendee(data)));
      return true;
   };
}

export function uncheckinMember(eventId, memberId) {
   return async (dispatch, getState) => {
      const {status, data} = await api.fetch(`/events/${eventId}/uncheckin`, {
         method: 'post',
         body: JSON.stringify({member_id: memberId})
      });
      if (status === StatusCodes.OK) {
         if (data.member_id) {
            dispatch(updateAttendee(eventId, new Attendee(data)));
         } else {
            dispatch(deleteAttendee(eventId, memberId));
         }
      }
   };
}

export function showError(message, error) {
   return dispatch => {
      console.log(error);
      Sentry.captureException(error);
      return dispatch(displayError(message));
   };
}
