import TimerIcon from "@material-ui/icons/Timer";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import React from "react";
import {useDispatch} from "react-redux";
import {waitlist} from "../actions";
import {useAuth} from "../contexts/auth";
import EventCardAction from "./EventCardAction";


const EventCardActionWaitlist = ({event}) => {

   const {user} = useAuth();
   const dispatch = useDispatch();

   let icon, iconColor, label, buttonLabel, buttonVariant, buttonOnClick;
   if (event.getAttendees(['WL']).find(a => a.member_id === user.member_id)) {
      icon = TimerIcon;
      iconColor = "primary";
      label = "You are in the waitlist";
      buttonLabel = 'Leave';
      buttonVariant = 'text';
      buttonOnClick = () => dispatch(waitlist(event.id, user.member_id, false));
   } else {
      icon = RemoveCircleOutlineIcon;
      label = "The event is full";
      buttonLabel = "Join waitlist";
      buttonVariant = "outlined";
      buttonOnClick = () => dispatch(waitlist(event.id, user.member_id, true));
   }

   return (
      <EventCardAction icon={icon}
                       iconColor={iconColor}
                       label={label}
                       buttonLabel={buttonLabel}
                       buttonVariant={buttonVariant}
                       buttonOnClick={buttonOnClick}/>
   );
}

export default EventCardActionWaitlist;
