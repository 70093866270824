import {red} from '@material-ui/core/colors';
import {createMuiTheme} from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
   palette: {
      primary: {
         main: '#008FB5'
      },
      secondary: {
         main: '#80CED7'
      },
      error: {
         main: red.A400
      },
      background: {
         default: '#fff'
      }
   },
   overrides: {
      // Style sheet name ⚛️
      MuiContainer: {
         // Name of the rule
         root: {
            paddingLeft: 0,
            paddingRight: 0
         }
      }
   }
});

export default theme;
