import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect} from "react-router";
import {requestLoginCode} from '../actions';
import {useAuth} from "../contexts/auth";
import {isValidCode, isValidEmailAddress} from "../utils";
import {LayoutLoggedOut} from "./Layout";

const queryString = require('query-string');

const useStyles = makeStyles(theme => ({
   submit: {
      margin: theme.spacing(1, 0, 2),
      width: '90%'
   }
}));

const LoginScene = ({location, history}) => {

   const {user, login} = useAuth();
   const dispatch = useDispatch();
   const classes = useStyles();
   const qs = queryString.parse(location.search);
   const autoEmail = qs.e && isValidEmailAddress(qs.e) ? qs.e : '';
   const autoSupportEmail = qs.e && isValidEmailAddress(qs.s) ? qs.s : '';
   const [email, setEmail] = useState(autoEmail);
   const [codeRequested, setCodeRequested] = useState(Boolean(autoEmail));
   const [supportEmail, setSupportEmail] = useState(autoSupportEmail);
   const [code, setCode] = useState('');
   const [isRequestingCode, setIsRequestingCode] = useState(false);
   const [isLoggingIn, setIsLoggingIn] = useState(false);

   if (user) return <Redirect to='/'/>;

   if (codeRequested) {
      return (
         <LayoutLoggedOut>
            <img src="/static/logo.svg" width="248" alt="Arrivaly"/>
            <Typography
               component="h1"
               variant="h5"
               style={{marginTop: '20px'}}
            >
               Check your inbox!
            </Typography>
            <Typography
               variant="body2"
               color="textSecondary"
               align="center"
               style={{padding: '15px 20px 0 20px'}}
            >
               We've emailed you a code to access Arrivaly. Please enter it
               here:
            </Typography>
            <TextField
               variant="outlined"
               margin="normal"
               id="code"
               label="Code"
               name="code"
               autoFocus
               style={{width: '90%', marginTop: '20px'}}
               value={code}
               onChange={e => setCode(e.target.value)}
            />
            <Button
               type="submit"
               variant="contained"
               color="primary"
               className={classes.submit}
               disabled={!isValidCode(code) || isLoggingIn}
               onClick={async () => {
                  setIsLoggingIn(true);
                  if (await login(email, code)) {
                     history.push('/');
                     setIsLoggingIn(false);
                  }
               }}
            >
               {isLoggingIn ? "Signing in..." : "Sign in"}
            </Button>
            <Typography
               variant="body2"
               color="textSecondary"
               align="center"
               style={{padding: '10px 20px 0 20px'}}
            >
               If you don't receive this email in 2 minutes,{' '}
               <Link
                  href="/"
                  onClick={e => {
                     e.preventDefault();
                     setCodeRequested(false);
                  }}
               >
                  try again
               </Link>
               .
            </Typography>
            {supportEmail && (
               <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  style={{padding: '15px 20px 0 20px'}}
               >
                  If you have any issues accessing your account, please contact{' '}
                  <Link href={"mailto:" + supportEmail}>
                     {supportEmail}
                  </Link>
                  .
               </Typography>
            )}
         </LayoutLoggedOut>
      );
   }

   return (
      <LayoutLoggedOut>
         <img src="/static/logo.svg" width="248" alt="Arrivaly"/>
         <TextField
            type="email"
            variant="outlined"
            margin="normal"
            id="email"
            label="Email address"
            name="email"
            autoComplete="email"
            autoFocus
            style={{width: '90%', marginTop: '20px'}}
            value={email}
            onChange={e => setEmail(e.target.value)}
         />
         <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!email || !isValidEmailAddress(email) || isRequestingCode}
            onClick={async () => {
               setIsRequestingCode(true);
               const supportEmail = await dispatch(requestLoginCode(email));
               if (supportEmail) {
                  setSupportEmail(supportEmail);
               }
               setCodeRequested(true);
               setIsRequestingCode(false);
            }}
         >
            {isRequestingCode ? "Please wait..." : "Sign In"}
         </Button>
      </LayoutLoggedOut>
   );
}

export default LoginScene;
