import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/styles";
import {ConnectedRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from 'history';
import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import ErrorMessage from "./components/ErrorMessage";
import {AuthProvider} from "./contexts/auth";
import createRootReducer from "./reducers";
import AccountScene from "./components/Account";
import EventScene from "./components/Event";
import EventsScene from "./components/Events";
import HelpScene from "./components/Help";
import HomeScene from "./components/Home";
import LoginScene from "./components/Login";
import SignupScene from "./components/Signup";
import theme from './theme';


export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore(preloadedState) {
   const store = createStore(
      createRootReducer(history), // root reducer with router state
      preloadedState,
      composeEnhancers(
         applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunkMiddleware // to dispatch() functions
         )
      )
   );
   return store;
}

const store = configureStore(/* provide initial state if any */);


const App = () => (
   <Provider store={store}>
      <ConnectedRouter history={history}>
         <ThemeProvider theme={theme}>
            <AuthProvider>
               <CssBaseline/>
               <Container>
                  <ErrorMessage/>
                  <Router>
                     <Route path="/" exact component={HomeScene}/>
                     <Route path="/login" exact component={LoginScene}/>
                     <Route path="/signup" exact component={SignupScene}/>
                     <Route path="/account" exact component={AccountScene}/>
                     <Route path="/events" exact component={EventsScene}/>
                     <Route path="/events/:id" exact component={EventScene}/>
                     <Route path="/help" exact component={HelpScene}/>
                  </Router>
               </Container>
            </AuthProvider>
         </ThemeProvider>
      </ConnectedRouter>
   </Provider>
);

export default App;
