import {Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from 'react';
import {Redirect} from "react-router";
import {useAuth} from "../contexts/auth";
import {LayoutLoggedIn} from "./Layout";

const useStyles = makeStyles(theme => ({
   media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
   }
}));

const HelpScene = () =>{

   const {user} = useAuth();
   const classes = useStyles();

   if (!user) return <Redirect to='/'/>;

   return (
      <LayoutLoggedIn title="Help" p={3}>
         <Card className={classes.root}>
            <CardActionArea>
               <CardMedia
                  className={classes.media}
                  image="static/help.jpeg"
                  title={"Help"}
               />
               <CardContent>
                  <Typography variant="body2" color="textSecondary"
                              component="p">
                     If you need help or have any queries on your
                     account,
                     contact <Link
                     href={"mailto:" + user.club.email}>{user.club.email}</Link>
                  </Typography>
               </CardContent>
            </CardActionArea>
         </Card>
      </LayoutLoggedIn>
   );
}

export default HelpScene;
