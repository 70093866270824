import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearError} from '../actions';

const useStyles = makeStyles(theme => ({
   close: {
      padding: theme.spacing(0.5)
   }
}));

const ErrorMessage = () => {

   const message = useSelector(state => state.app.errorMessage);
   const open = useSelector(state => Boolean(state.app.errorMessage));
   const classes = useStyles();
   const dispatch = useDispatch();
   const clear = () => dispatch(clearError());

   return (
      <Snackbar
         anchorOrigin={{vertical: 'top', horizontal: 'center'}}
         open={open}
         autoHideDuration={6000}
         onClose={clear}
         ContentProps={{'aria-describedby': 'message-id'}}
         message={<span id="message-id">{message}</span>}
         action={[
            <IconButton
               key="close"
               aria-label="close"
               color="inherit"
               className={classes.close}
               onClick={clear}
            >
               <CloseIcon/>
            </IconButton>
         ]}
      />
   );
}

export default ErrorMessage;
