import {Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from "@material-ui/core/styles/makeStyles";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React from "react";

const useStyles = makeStyles(theme => ({
   avatar: {
      display: "flex",
      alignItems: "center",
   },
   tags: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      '& > *': {
         margin: theme.spacing(0.25),
      },
   },
}));

const SelectMemberListItem = ({data, style, index}) => {

   const classes = useStyles();
   const item = data.get(index);

   let icon = <PersonAddIcon/>;
   if (item.status === "unselectable") {
      icon = <RemoveCircleOutlineIcon/>;
   } else if (item.status === "selected") {
      icon = <PersonAddDisabledIcon/>;
   } else if (item.status === "selecting") {
      icon = <CircularProgress size={25} color="primary"/>;
   }

   return (
      <ListItem button key={item.member.id} style={style}
                onClick={() => item.onClick(item.member)} disabled={item.disabled}>
         <ListItemAvatar className={classes.avatar}>
            {icon}
         </ListItemAvatar>
         <ListItemText disableTypography={true}>
            <Typography noWrap={true}>{item.member.name}</Typography>
         </ListItemText>
         <div className={classes.tags}>
            {item.tags.map(tag => <Chip size="small" label={tag} key={item.member.id + tag}/>)}
         </div>
      </ListItem>
   );
};

export default SelectMemberListItem;
