import {Typography} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, {useState} from 'react';

const ConfirmationDialog = (props) => {

   const {title, text, checkbox, confirmLabel, cancelLabel, onConfirm, onCancel} = props;
   const [checked, setChecked] = useState(false);

   return (
      <div>
         <Dialog open={true} onClose={onCancel}>
            <DialogTitle>
               {title}
            </DialogTitle>
            <DialogContent>
               {text.map((paragraph, idx) =>
                  <DialogContentText key={`text-${idx}`}
                                     variant="body2">{paragraph}</DialogContentText>
               )}
               {checkbox && (
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={checked}
                           onChange={() => setChecked(!checked)}
                           name="checkbox"
                           color="primary"
                        />
                     }
                     label={<Typography variant="body2"
                                        color="textSecondary">{checkbox}</Typography>}
                  />
               )}
            </DialogContent>
            <DialogActions>
               <Button autoFocus onClick={onCancel} color="primary">
                  {cancelLabel}
               </Button>
               <Button onClick={onConfirm} color="primary"
                       disabled={checkbox && !checked}>
                  {confirmLabel}
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
};

export default ConfirmationDialog;
