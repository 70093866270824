import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import {LayoutLoggedIn} from "./Layout";
import SelectMemberList from './SelectMemberList';

const useStyles = makeStyles({
   searchbox: {
      marginTop: 8
   }
});

const SelectMember = ({members, onMemberSelected, onCancel}) => {

   const classes = useStyles();
   const [query, setQuery] = useState('');
   const [displayedMembers, setDisplayedMembers] = useState(sortMembers(members));

   const onSearch = (e) => {
      setQuery(e.target.value);
      const query = e.target.value.toLowerCase();
      const filteredMembers = members.filter(m => {
         // Reasonably fast approximation to matching first or last name
         const names = m.member.name.toLowerCase().split(' ');
         if (names.length === 1) {
            return names[0].startsWith(query);
         } else {
            return names[0].startsWith(query) || names[names.length - 1].startsWith(query);
         }
      });
      setDisplayedMembers(sortMembers(filteredMembers));
   };

   return (
      <LayoutLoggedIn title="Check in member" onIconClicked={onCancel} p={1}>
         <TextField
            id="outlined-search"
            label="Search"
            type="search"
            margin="normal"
            variant="outlined"
            fullWidth
            value={query}
            onChange={onSearch}
            className={classes.searchbox}
            autoComplete="off"
         />
         <SelectMemberList
            members={displayedMembers}
            onMemberSelected={member => onMemberSelected(member)}
         />
      </LayoutLoggedIn>
   );
}

export default SelectMember;


function sortMembers(members) {
   let sortedMembers = [...members];
   // All booked members at the beginning
   sortedMembers.sort((m1, m2) => m2.booked - m1.booked);
   // All cancelled members right after
   sortedMembers.sort((m1, m2) => m2.cancelled - m1.cancelled);
   // All unselectable members at the end
   sortedMembers.sort((m1, m2) =>
      (m1.status === "unselectable") - (m2.status === "unselectable")
   );
   return sortedMembers;
}
