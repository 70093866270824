import CircularProgress from "@material-ui/core/CircularProgress";
import EventIcon from "@material-ui/icons/Event";
import TimerIcon from "@material-ui/icons/Timer";
import * as moment from "moment";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {bookMember} from "../actions";
import {useAuth} from "../contexts/auth";
import ConfirmationDialog from "./ConfirmationDialog";
import EventCardAction from "./EventCardAction";


const EventCardActionBook = ({event}) => {

   const {user} = useAuth();
   const dispatch = useDispatch();
   const [isConfirmingBook, setIsConfirmingBook] = useState(false);
   const [eventBeingBooked, setEventBeingBooked] = useState(false);
   const now = new Date();

   const bookEvent = async () => {
      setEventBeingBooked(true);
      await dispatch(bookMember(event.id, user));
      setEventBeingBooked(false);
   };

   let icon, iconColor, label;
   let buttonLabel = "Book";
   let buttonDisabled = false;
   if (event.booking_date < now) {
      icon = EventIcon;
      iconColor = "secondary";
      if (event.price) {
         label = `Book now for ${event.getPriceToDisplay()}`;
      } else {
         label = "Booking is open!";
      }
   } else {
      icon = TimerIcon;
      iconColor = "disabled";
      label = "Opens " + moment(event.booking_date).fromNow();
      buttonDisabled = true;
   }
   if (eventBeingBooked) {
      label = 'Booking...';
      buttonLabel = <CircularProgress color="inherit" size="1.35rem"/>;
      buttonDisabled = true;
   }

   const buttonOnClick = () => {
      if (event.price) {
         setIsConfirmingBook(true);
      } else {
         bookEvent();
      }
   };

   return (
      <div>
         {isConfirmingBook && (
            <ConfirmationDialog
               title="Confirm booking"
               text={[
                  `Booking this event requires a non-refundable payment of £${event.price}.`,
                  "Do you want to proceed?"
               ]}
               checkbox={`I accept ${user.club.name}'s T&Cs`}
               confirmLabel="Yes"
               cancelLabel="No"
               onConfirm={() => {
                  setIsConfirmingBook(false);
                  bookEvent();
               }}
               onCancel={() => setIsConfirmingBook(false)}
            />
         )}
         <EventCardAction icon={icon}
                          iconColor={iconColor}
                          label={label}
                          buttonLabel={buttonLabel}
                          buttonDisabled={buttonDisabled}
                          buttonOnClick={buttonOnClick}/>
      </div>
   );
}

export default EventCardActionBook;
